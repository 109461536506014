<template>
  <v-dialog
    v-if="Object.keys(actualCaseForm).length"
    v-model="dialog"
    persistent
    keydown
    max-width="800px"
    transition="dialog-bottom-transition"
    content-class="vdialognew"
  >
    <v-card class="pa-2">
      <v-card-title
        v-if="actualCaseForm.name.length >= 40"
      >
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <div
              v-bind="attrs"
              class="font-weight-bold"
              style="font-size: 18px;"
              v-on="on"
            >
              {{ $t('case_models|create_new_case') }} {{ $t('common|from') }}: {{ actualCaseForm.name.slice(0, 40) + '...' }}
            </div>
          </template>
          <span>{{ actualCaseForm.name }}</span>
        </v-tooltip>
      </v-card-title>
      <v-card-title
        v-else
        style="font-size: 18px;"
      >
        {{ $t('case_models|create_new_case') }} {{ $t('common|from') }}: {{ actualCaseForm.name }}
      </v-card-title>
      <v-btn
        icon
        style="position: absolute; top: 10px; right: 10px;"
        @click="dialog = false"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <v-card-text>
        <div v-if="company">
          <v-stepper
            v-model="e6"
            vertical
            flat
          >
            <v-stepper-step
              :complete="e6 > 1"
              step="1"
            >
              {{ $t('actions|select_section') }}
              <small
                v-if="destination"
                class="mt-1"
              >
                {{ destination.text }}
              </small>
            </v-stepper-step>

            <v-stepper-content step="1">
              <v-card
                flat
                class="mb-12"
                height="50px"
              >
                <v-card-text>
                  <v-select
                    v-model="destination"
                    :items="availableSections"
                    :label="$t('actions|select')"
                    item-disabled="disabled"
                    item-text="text"
                    item-value="id"
                    return-object
                    outlined
                    dense
                    class="my-auto"
                  />
                </v-card-text>
              </v-card>
            </v-stepper-content>

            <v-stepper-step
              :complete="e6 > 2"
              step="2"
            >
              {{ $t('cases|choose_client') }}
              <small
                v-if="selectedClient"
                class="mt-1"
              >
                {{ selectedClient.displayName }}
              </small>
            </v-stepper-step>

            <v-stepper-content step="2">
              <v-card
                flat
                class="mb-12"
                height="50px"
              >
                <v-select
                  v-if="destination && account.accountType === 'lawyer'"
                  v-model="selectedClient"
                  :items="getClients(destination)"
                  :label="$t('actions|select_client')"
                  item-text="displayName"
                  item-value="_id"
                  required
                  return-object
                  :rules="[(v) => !!v || $t('fields|required')]"
                  outlined
                  dense
                  class="mt-6 mx-4"
                />
              </v-card>
              <v-btn
                text
                rounded
                class="mb-1"
                @click="e6--; destination = null"
              >
                <v-icon
                  class="mr-1"
                >
                  mdi-undo
                </v-icon>
                {{ $t('actions|back') }}
              </v-btn>
            </v-stepper-content>

            <v-stepper-step
              :complete="e6 > 3"
              step="3"
            >
              {{ $t('cases|case_name') }}
              <small
                v-if="caseName"
                class="mt-1"
              >
                {{ caseName }}
              </small>
            </v-stepper-step>

            <v-stepper-content step="3">
              <v-card
                flat
                class="mb-12"
                height="50px"
              >
                <v-text-field
                  v-model="caseName"
                  outlined
                  required
                  :rules="[(v) => !!v || $t('fields|required')]"
                  dense
                  :label="$t('cases|enter_name')"
                  class="mt-4 mx-4"
                />
              </v-card>
              <v-btn
                text
                rounded
                class="mb-1"
                @click="e6--; selectedClient = null"
              >
                <v-icon
                  class="mr-1"
                >
                  mdi-undo
                </v-icon>
                {{ $t('actions|back') }}
              </v-btn>
            </v-stepper-content>
          </v-stepper>
        </div>
        <div v-else>
          <v-select
            v-if="account.accountType === 'lawyer'"
            v-model="selectedClient"
            :items="clientsList"
            :label="$t('actions|select_client')"
            item-text="displayName"
            item-value="_id"
            required
            return-object
            :rules="[(v) => !!v || $t('fields|required')]"
            outlined
            dense
            class="mt-6 mx-4"
          />
          <v-text-field
            v-model="caseName"
            outlined
            required
            :rules="[(v) => !!v || $t('fields|required')]"
            dense
            :label="$t('cases|case_name')"
            class="mx-4"
          />
        </div>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          color="primary"
          small
          rounded
          min-width="150"
          :disabled="!caseName.length || !selectedClient"
          :loading="loading"
          @click="submit"
        >
          {{ $t('actions|submit') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { EventBus } from '@/utils/EventBus'
import { userHasAccess } from '@/utils/utils'
import generalMixin from '@/utils/generalMixin.js'

export default {
  mixins: [
    generalMixin
  ],
  data () {
    return {
      dialog: false,
      selectedClient: null,
      caseName: '',
      actualCaseForm: {},
      loading: false,
      e6: 1,
      destination: null
    }
  },
  computed: {
    ...mapState({
      account: state => state.account.account,
      company: state => state.company.company,
      clients: state => state.clients.clients,
      companyClients: state => state.companyClients.companyClients,
      groupClients: state => state.groupClients.groupClients
    }),
    clientsList () {
      const clients = this.clients.map((client) => {
        let displayName
        if (client.clientType === 'individual') {
          if (client.clientData.given_names) {
            displayName = `${client.clientData.given_names} ${client.clientData.surname}`
          } else {
            displayName = 'Pending Name'
          }
        } else {
          if (client.clientData.company_name) {
            displayName = client.clientData.company_name
          } else {
            displayName = 'Pending Company Name'
          }
        }
        return {
          _id: client._id,
          displayName
        }
      })
      return clients
    },
    _companyClients () {
      let clients = []
      if (this.destination && this.company && this.company._id === this.destination.id) {
        clients = this.companyClients.map((client) => {
          let displayName
          if (client.clientType === 'individual') {
            if (client.clientData.given_names) {
              displayName = `${client.clientData.given_names} ${client.clientData.surname}`
            } else {
              displayName = 'Pending Name'
            }
          } else {
            if (client.clientData.company_name) {
              displayName = client.clientData.company_name
            } else {
              displayName = 'Pending Company Name'
            }
          }
          return {
            _id: client._id,
            displayName
          }
        })
      }
      return clients
    },
    _groupClients () {
      let group = null
      let clients = []
      if (this.destination) {
        group = this.company.groups.find(gr => gr._id === this.destination.id)
      }
      if (group) {
        clients = this.groupClients.filter(cl => cl.sharedWith.groups.indexOf(group._id) !== -1).map((client) => {
          let displayName
          if (client.clientType === 'individual') {
            if (client.clientData.given_names) {
              displayName = `${client.clientData.given_names} ${client.clientData.surname}`
            } else {
              displayName = 'Pending Name'
            }
          } else {
            if (client.clientData.company_name) {
              displayName = client.clientData.company_name
            } else {
              displayName = 'Pending Company Name'
            }
          }
          return {
            _id: client._id,
            displayName
          }
        })
      }
      return clients
    },
    availableSections () {
      let myAvailableSections = []
      const getAccess = (_acc, _comp, _group, _tab, _from) => {
        return userHasAccess(_acc, _comp, _group, _tab, _from)
      }
      const accountAccess = getAccess(this.account, null, null, null, 'onlyAccountAccess')
      const companyAccess = getAccess(this.account, this.company, null, null, 'availableSections')
      if (this.account && this.account.companyId && this.company) {
        const userRole = this.verifyUserRole(this.company, this.account)
        // verifyUserRole could be found in generalMixin
        if (userRole === 'regular') {
          myAvailableSections.push({
            text: this.company.companyData.companyName + ' ' + this.$t('cases|cases').toLowerCase(),
            id: this.company._id,
            disabled: !companyAccess
          })
          for (let i = 0; i < this.company.groups.length; i++) {
            const group = this.company.groups[i]
            const participation = group.groupMembers.find(m => m._id === this.account._id)
            if (participation) {
              myAvailableSections.push({
                text: group.groupName + ' ' + this.$t('cases|cases').toLowerCase(),
                id: group._id,
                disabled: !companyAccess
              })
            }
          }
        } else {
          myAvailableSections.push({
            text: this.$t('common|my') + ' ' + this.$t('cases|cases').toLowerCase(),
            id: this.account._id,
            disabled: !accountAccess
          })
          myAvailableSections.push({
            text: this.company.companyData.companyName + ' ' + this.$t('cases|cases').toLowerCase(),
            id: this.company._id,
            disabled: !companyAccess
          })
          for (let i = 0; i < this.company.groups.length; i++) {
            const group = this.company.groups[i]
            const participation = group.groupMembers.find(m => m._id === this.account._id)
            if (participation) {
              myAvailableSections.push({
                text: group.groupName + ' ' + this.$t('cases|cases').toLowerCase(),
                id: group._id,
                disabled: !companyAccess
              })
            }
          }
        }
      }
      return myAvailableSections
    }
  },
  watch: {
    dialog (value) {
      if (!value) {
        this.reset()
      }
    },
    destination (value) {
      if (value) this.e6++
    },
    selectedClient (value) {
      if (value) this.e6++
    }
  },
  created () {
    EventBus.$on('dialog-add-case-from-case-model', this.onAddCaseEvent)
  },
  beforeDestroy () {
    EventBus.$off('dialog-add-case-from-case-model', this.onAddCaseEvent)
  },
  methods: {
    ...mapActions({
      addToast: 'toasts/addToast',
      addCase: 'cases/addCase',
      addCaseFromCaseModel: 'cases/addCaseFromCaseModel'
    }),
    getClients (destination) {
      let isGroup = null
      if (this.company) {
        const index = this.company.groups.findIndex(gr => gr._id === destination.id)
        if (index !== -1) isGroup = true
      }
      if (destination.id === this.company._id) return this._companyClients
      else if (isGroup) return this._groupClients
      else return this.clientsList
    },
    onAddCaseEvent (caseForm) {
      this.actualCaseForm = caseForm
      this.dialog = true
    },
    reset () {
      this.selectedClient = null
      this.caseName = ''
      this.actualCaseForm = {}
      this.destination = null
      this.e6 = 1
    },
    async submit () {
      this.loading = true
      const _id = this.actualCaseForm._id
      const payload = {
        clientId: this.selectedClient._id,
        caseName: this.caseName,
        destination: this.destination
      }
      try {
        await this.addCaseFromCaseModel({ _id, payload })
      } catch (e) {
        this.addToast({
          title: `${this.$t('expressions|error_occurred')}`,
          color: 'white',
          snackbarColor: 'error'
        })
        console.error(e, 'error')
        this.loading = false
        this.dialog = false
        this.reset()
      } finally {
        this.addToast({
          title: `${this.$t('cases|successfully_created_new_case')}: ${this.caseName}`,
          color: 'white',
          snackbarColor: 'success'
        })
        this.loading = false
        this.dialog = false
        this.reset()
      }
    }
  }
}
</script>

<style>
.vdialognew {
  box-shadow: none !important;
}
</style>
