<template>
  <div :key="componentKey">
    <v-data-table
      class="rounded-b-xs rounded-t-0"
      min-height="100%"
      min-width="100%"
      :headers="headers"
      :items="filteredCaseModelsByCategory"
      sort-by="name"
      :height="($vuetify.breakpoint.height - 260).toString() + 'px'"
    >
      <template v-slot:item.name="{ item, index }">
        <v-hover
          v-slot="{ hover }"
        >
          <div class="row">
            <v-col cols="1">
              <v-icon
                color="primary"
                class="mt-1"
              >
                {{ icons.caseModel }}
              </v-icon>
            </v-col>
            <v-col cols="10">
              <div
                :id="`case-model-name-${index}`"
                class="text--black"
                :contenteditable="hover || nameFocus === index"
                :style="hover || nameFocus === index ? `width: 200px; outline: none; color: ${$vuetify.theme.themes.light.primary}; background-color:  ${$vuetify.theme.themes.light.background}; border-radius: 10px; padding: 5px;` : `width: 200px; padding: 5px;`"
                style="min-height: 30px;"
                @focus="nameFieldFocus(index)"
                @blur="changeCaseModelName(index, item)"
              >
                {{ item.name }}
              </div>
            </v-col>
          </div>
        </v-hover>
      </template>
      <template v-slot:item.category="{ item }">
        <div>
          {{ translateCategoryIfNeeded(item.category) }}
          <v-menu
            bottom
            left
            offset-y
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>mdi-menu-down</v-icon>
              </v-btn>
            </template>

            <v-list
              v-if="availableCategories(item).length"
              style="max-height: 400px; overflow-y: auto;"
            >
              <v-list-item
                v-for="(cat, i) in availableCategories(item)"
                :key="i"
                three-line
                @click="changeCaseModelCategory(item, cat.value)"
              >
                <v-list-item-content>
                  <v-list-item-title>
                    <v-icon
                      x-small
                    >
                      mdi-tag-outline
                    </v-icon>
                    {{ cat.value }}
                  </v-list-item-title>
                  <v-list-item-subtitle>{{ $t('common|created_by') }}:</v-list-item-subtitle>
                  <v-list-item-subtitle>{{ cat.createdBy }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list>
            <div
              v-else
              class="pa-3"
              style="background-color: white;"
            >
              {{ $t('mixed|no_available_categories') }}
            </div>
          </v-menu>
        </div>
      </template>
      <template v-slot:item.description="{ item, index }">
        <v-hover
          v-slot="{ hover }"
        >
          <div>
            <div
              :id="`case-model-description-${index}`"
              class="text--black"
              :contenteditable="hover || focus === index"
              :style="hover || focus === index ? `width: 180px; outline: none; color: ${$vuetify.theme.themes.light.primary}; background-color:  ${$vuetify.theme.themes.light.background}; border-radius: 10px; padding: 5px;` : `width: 180px; padding: 5px;`"
              style="min-height: 30px;"
              @focus="fieldFocus(index)"
              @blur="changeDescription(index, item)"
            >
              {{ item.description }}
            </div>
          </div>
        </v-hover>
      </template>
      <template v-slot:item.sharedWith="{ item }">
        <div
          v-for="(acc, idx) in item.sharedWith.accounts"
          :key="idx"
        >
          <v-chip
            x-small
            outlined
            color="primary"
          >
            <v-avatar
              size="30px"
              class="ml-1 mr-1"
            >
              <img
                v-if="acc && acc.avatar"
                alt="Avatar"
                :src="`${cfg.baseURL}downloadAvatar/${acc._id}/${acc.avatar.raw.filename}`"
              >
              <v-avatar
                v-else
              >
                <v-icon
                  color="primary"
                  small
                  class="ml-n3"
                >
                  mdi-account
                </v-icon>
              </v-avatar>
            </v-avatar>
            <span
              class="text-truncate"
            >
              {{ getName(acc) }}
            </span>
          </v-chip>
        </div>
        <div
          v-for="_company in item.sharedWith.company"
          :key="_company._id"
        >
          <v-chip
            x-small
            outlined
            color="primary"
          >
            <v-icon
              color="primary"
              small
            >
              mdi-domain
            </v-icon>
            <span
              class="text-truncate ml-2"
            >
              {{ _company.companyData.companyName }}
            </span>
          </v-chip>
        </div>
        <div
          v-for="_group in item.sharedWith.groups"
          :key="_group._id"
        >
          <v-chip
            x-small
            outlined
            color="primary"
          >
            <v-icon
              color="primary"
              small
            >
              mdi-account-group
            </v-icon>
            <span
              class="text-truncate ml-2"
            >
              {{ _group.groupName }}
            </span>
          </v-chip>
        </div>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              v-bind="attrs"
              color="primary"
              v-on="on"
              @click="viewCaseForm(item)"
            >
              mdi-file-eye
            </v-icon>
          </template>
          <span>{{ $t('actions|open') }}</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              v-bind="attrs"
              color="primary"
              v-on="on"
              @click="useCaseForm(item)"
            >
              mdi-link
            </v-icon>
          </template>
          <span>{{ $t('actions|use') }}</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <div v-on="!account.comapnyId ? on : ''">
              <v-btn
                v-if="account.accountType !== 'god' && item && !item.shared"
                icon
                :disabled="disabledRule"
                v-bind="attrs"
                @click.stop.native="shareCaseForm(item)"
                v-on="on"
              >
                <v-icon
                  size="22"
                  color="primary"
                >
                  mdi-share-variant
                </v-icon>
              </v-btn>
            </div>
          </template>
          <span>{{ account.companyId ? $t('actions|share') : $t('expressions|activate_members_plan_to_share') }}</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-if="item.pinned.findIndex(id => id === account._id) === -1"
              icon
              v-bind="attrs"
              v-on="on"
              @click.stop="pinToDashboard(item)"
            >
              <v-icon
                color="primary"
              >
                mdi-pin-outline
              </v-icon>
            </v-btn>
          </template>
          <span>{{ $t('actions|add_to_pinboard') }}</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-if="item.pinned.includes(account._id)"
              icon
              v-bind="attrs"
              v-on="on"
              @click.stop="removePinFromDashboard(item)"
            >
              <v-icon
                color="primary"
              >
                mdi-pin
              </v-icon>
            </v-btn>
          </template>
          <span>{{ $t('actions|remove_from_pinboard') }}</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              v-bind="attrs"
              color="primary"
              v-on="on"
              @click="deleteCaseForm(item)"
            >
              mdi-delete
            </v-icon>
          </template>
          <span>{{ $t('actions|delete') }}</span>
        </v-tooltip>
      </template>
    </v-data-table>
    <AddCaseFromCaseModel
      ref="AddCaseFromCaseModel"
    />
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { EventBus } from '@/utils/EventBus'
import cfg from '@/config'
import generalMixin from '@/utils/generalMixin'
import caseModelMixin from './CaseModelMixins/caseModelMixin'
import AddCaseFromCaseModel from '../../components/dialogs/AddCaseFromCaseModel.vue'

export default {
  components: { AddCaseFromCaseModel },
  mixins: [generalMixin, caseModelMixin],
  props: {
    filteredCaseModelsByCategory: {
      type: Array,
      default: null
    }
  },
  data () {
    return {
      cfg,
      icons: {
        caseModel: 'mdi-briefcase-edit-outline'
      },
      focus: null,
      nameFocus: null,
      componentKey: 1,
      headers: [
        {
          text: this.$t('case_models|case_model_name'),
          align: 'start',
          sortable: true,
          value: 'name'
        },
        {
          text: this.$t('common|category'),
          align: 'start',
          sortable: true,
          value: 'category',
          width: '250px'
        },
        {
          text: this.$t('common|description'),
          align: 'start',
          sortable: true,
          value: 'description'
        },
        {
          text: this.$t('common|shared_with'),
          value: 'sharedWith',
          sortable: false
        },
        {
          text: this.$t('common|date_created'),
          value: 'createdAt',
          sortable: true,
          width: '160px'
        },
        {
          text: this.$t('actions|actions'),
          value: 'actions',
          sortable: false,
          align: 'end',
          width: '150px'
        }
      ]
    }
  },
  computed: {
    ...mapState({
      account: state => state.account.account,
      actualTabCaseForms: state => state.caseForms.actualTabCaseForms
    }),
    filteredModels () {
      return this.filteredCaseModelsByCategory
    },
    disabledRule () {
      let rule
      if (this.account.companyId) rule = false
      else rule = true
      return rule
    }
  },
  watch: {
    filteredModels (val) {
      if (val) this.componentKey++
    }
  },
  created () {
    EventBus.$on('refresh-case-models', this.onRefreshCaseModels)
  },
  beforDestroy () {
    EventBus.$off('refresh-case-models', this.onRefreshCaseModels)
  },
  methods: {
    ...mapActions({
      fetchCaseForms: 'caseForms/fetchCaseForms',
      updateCaseForm: 'caseForms/updateCaseForm',
      deleteCaseFormAction: 'caseForms/deleteCaseForm',
      addToast: 'toasts/addToast'
    }),
    onRefreshCaseModels () {
      this.componentKey++
    },
    fieldFocus (idx) {
      this.focus = idx
    },
    nameFieldFocus (idx) {
      this.nameFocus = idx
    },
    async changeCaseModelName (idx, currentCaseForm) {
      this.nameFocus = null
      const element = document.getElementById(`case-model-name-${idx}`)
      const res = element.textContent.trimStart().trimEnd()
      const payload = {
        name: res
      }
      try {
        await this.updateCaseForm({ _id: currentCaseForm._id, payload })
      } catch (e) {
        console.error(e, 'error')
      }
    },
    useCaseForm (caseForm) {
      this.$refs.AddCaseFromCaseModel.actualCaseForm = caseForm
      this.$refs.AddCaseFromCaseModel.dialog = true
    },
    viewCaseForm (caseForm) {
      const workbench = caseForm.workbenches[0]
      this.$router.push({ path: `/models/${caseForm._id}/${workbench._id}` })
    },
    async deleteCaseForm (caseForm) {
      const res = await this.$dialog.confirm({
        text: this.$t('case_models|warning_delete_model'),
        title: this.$t('common|warning')
      })
      if (res) {
        try {
          await this.deleteCaseFormAction(caseForm)
        } catch (e) {
          console.error(e, 'e')
        }
      }
    },
    async shareCaseForm (caseForm) {
      EventBus.$emit('dialog-share-case-model', caseForm, this.actualTabCaseForms)
    },
    checkIfPinned (caseForm) {
      const isPinned = caseForm.pinned.findIndex(id => id === this.account._id)
      if (isPinned === -1) return false
      else return true
    },
    async pinToDashboard (caseForm) {
      const payload = {
        pinned: this.account._id
      }
      try {
        await this.updateCaseForm({
          _id: caseForm._id,
          payload
        })
        this.addToast({
          title: this.$t('message|case_model_pinned'),
          color: 'white',
          snackbarColor: 'success'
        })
      } catch (e) {
        this.addToast({
          title: this.$t('error|case_model_pin_failed'),
          color: 'white',
          snackbarColor: 'error'
        })
      } finally {
        this.componentKey++
      }
    },
    async removePinFromDashboard (caseForm) {
      const payload = {
        removePinId: this.account._id
      }
      try {
        await this.updateCaseForm({
          _id: caseForm._id,
          payload
        })
        this.addToast({
          title: this.$t('message|case_model_removed_from_dashboard'),
          color: 'white',
          snackbarColor: 'success'
        })
      } catch (e) {
        this.addToast({
          title: this.$t('error|case_model_removal_from_dashboard_failed'),
          color: 'white',
          snackbarColor: 'error'
        })
      } finally {
        this.componentKey++
      }
    }
  }
}
</script>
